import { Link, useMatch, useResolvedPath } from "react-router-dom"
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EuroIcon from '@mui/icons-material/Euro';
import CurrencyYuanIcon from '@mui/icons-material/CurrencyYuan';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Typography from '@mui/material/Typography';


import Box from '@mui/material/Box';
import SavingsIcon from '@mui/icons-material/Savings';
// import {
//   AppBar,
//   Toolbar,
//   CssBaseline,
//   Typography,
//   makeStyles,
// } from "@material-ui/core";


export default function Navbar() {
  return (
    <nav className="nav">
      <Box display='flex' flexGrow={1}>
        <img src="save_loan_logo.png" width={100} height={35} />
      </Box>

      <ul>
        <CustomLink to="/saveloan">Home</CustomLink>
        <CustomLink to="/about">About</CustomLink>
      </ul>
    </nav>
    // <AppBar position="static">
    //   <CssBaseline />
    //   <Toolbar>
    //     <Typography variant="h4" className={classes.logo}>
    //       Navbar
    //     </Typography>
    //       <div className={classes.navlinks}>
    //         <Link to="/" className={classes.link}>
    //           Home
    //         </Link>
    //         <Link to="/saveloan" className={classes.link}>
    //           Save Loan
    //         </Link>
    //         <Link to="/about" className={classes.link}>
    //           About
    //         </Link>
    //       </div>
    //   </Toolbar>
    // </AppBar>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
