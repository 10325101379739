//import "./App.css";
import { useState } from "react";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { InputAdornment } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';



function SaveLoan() {
  const [principalLeft, setPrincipalLeft] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [principalError, setPrincipalError] = useState();

  const theme = {
    spacing: 2,
  }

  const [tableData,setTableData] = useState([]);
  const [hasTableData,setHasTableData] = useState(false);
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsFetching(true)
      console.log('calling api POST');
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ principal_left: principalLeft, payment_amount: paymentAmount, interest: interestRate })
      };
      const res = await fetch("https://3clbszm184.execute-api.us-east-1.amazonaws.com/prod/", requestOptions) ;

      const resJson = await res.json();
      console.log(resJson);
      setIsFetching(false)

      if (res.status === 200) {
        setTableData(resJson)
        setHasTableData(true)

        setMessage("Options to save loan");

      } else {
        setHasTableData(false)
        console.log(res.status);
        setErrorMessage("Ah-uh!!..some error occured");
      }
    } catch (err) {
      setHasTableData(false)
      setErrorMessage("Ah-uh!!..some error occured");
      setIsFetching(false)
      console.log(err);
    }
  };
  console.log('hasTableData',hasTableData)

  return (
    <>
    {/* <div className="SaveLoan"> */}
      <Grid container spacing={5}>
          <Grid item sm={12} md={6} lg={3} >
            <Card>
              <CardMedia
                sx={{ height: 140 }}
                image="static/images/cards/house1.jpg"
                title="house"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Save Loan
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Are you always concerned about how you will pay off your loan in another 20 or 30 years, when there are a lot of unknowns - how long can you earn, other financial needs, family priorities etc..
                </Typography>
              </CardContent>
            </Card>          
          </Grid>
          <Grid item sm={12} md={6} lg={3} >
            <Card>
              <CardMedia
                sx={{ height: 140 }}
                image="static/images/cards/dollar.jpg"
                title="dollar"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  What the banks usually don't tell you
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Do you know that you can save significantly on your overall repayment, and pay off your loan much quickly if you make a small increase in your regular payments. 
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={6} lg={3} >
            <Card>
              <CardMedia
                sx={{ height: 140 }}
                image="static/images/cards/calculator.jpg"
                title="calculator"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  But I don't know how much....
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  These calculations are usually very difficult for most people. That's where we help. Enter the details of your loan, and we provide some of the options you can go for. 
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
          <Grid container spacing={2} alignItems="center" direction="column">
          <Grid item xs={12} > 
              <TextField 
                id="outlined1" 
                sx={{ m: 1 }}
                type="number"
                label="Principal left" 
                variant="outlined" 
                required 
                value={principalLeft} 
                onChange={(e) => setPrincipalLeft(e.target.value)}
              />
              </Grid>
              <Grid item xs={12} >
              <TextField 
                id="outlined2" 
                sx={{ m: 1 }}
                type="number"
                label="Monthly repayment" 
                variant="outlined" 
                required 
                value={paymentAmount} 
                onChange={(e) => setPaymentAmount(e.target.value)}
              />
              </Grid>
              <Grid item xs={12} >
              <TextField 
                id="outlined3" 
                sx={{ m: 1 }}
                type="number"
                label="Annual interest rate" 
                variant="outlined" 
                required 
                value={interestRate} 
                onChange={(e) => setInterestRate(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }}
              />
              </Grid>
              <Grid item xs={12} alignItems="center">
              <Button sx={{ minWidth: '60px', maxWidth: '300px'}} variant="contained" size="medium" onClick={handleSubmit}>Get Savings</Button>
              </Grid>
            </Grid>
          </Grid>



    {/* </div> */}
    
    {!hasTableData && (<div>{errorMessage}</div>)}

    {isFetching && (<Box sx={{ width: '100%'}}>
      <LinearProgress />
    </Box>)}



  {hasTableData && (
  <Grid item xs={12} >
<TableContainer component={Paper} >
<Table aria-label="customized table">

  <TableHead>
    <TableRow>
      <StyledTableCell>Options</StyledTableCell>
      <StyledTableCell align="left">Loan finishes by</StyledTableCell>
      <StyledTableCell align="left">Number of Years to payoff</StyledTableCell>
      <StyledTableCell align="left">Total Amount to pay</StyledTableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {tableData.map((data) => (
      <StyledTableRow key={data.option}>
        <StyledTableCell component="th" scope="row">
          {data.option}
        </StyledTableCell>
        <StyledTableCell align="left">{data.year}</StyledTableCell>
        <StyledTableCell align="left">{data.num_years}</StyledTableCell>
        <StyledTableCell align="left">{data.total_amount}</StyledTableCell>
      </StyledTableRow>
    ))}
  </TableBody>
</Table>
</TableContainer>
</Grid>
)}
</Grid>


</>
  );
}

export default SaveLoan;
